/* eslint-disable import/no-duplicates */
import * as fns from 'date-fns'
import * as fnsLocales from 'date-fns/locale'
/* eslint-enable import/no-duplicates */
import * as fnsTz from 'date-fns-tz'

import i18n from '@lib/i18n'

const localesMap: Record<Locale, any> = {
  ar: fnsLocales.ar,
  bg: fnsLocales.bg,
  cs: fnsLocales.cs,
  da: fnsLocales.da,
  de: fnsLocales.de,
  en: fnsLocales.enGB,
  es: fnsLocales.es,
  fr: fnsLocales.fr,
  hr: fnsLocales.hr,
  it: fnsLocales.it,
  ja: fnsLocales.ja,
  nl: fnsLocales.nl,
  no: fnsLocales.nn,
  pl: fnsLocales.pl,
  pt: fnsLocales.pt,
  sq: fnsLocales.sq,
  sv: fnsLocales.sv,
  tr: fnsLocales.tr,
  vi: fnsLocales.vi,
  zh: fnsLocales.zhCN,
  'en-IE': fnsLocales.enIE,
  'en-US': fnsLocales.enUS,
  'en-CA': fnsLocales.enCA,
  'es-CO': fnsLocales.es,
  'es-EC': fnsLocales.es,
  'es-MX': fnsLocales.es,
  'pt-BR': fnsLocales.ptBR,
  'sk-SK': fnsLocales.sk,
  ur: fnsLocales.enGB,
  bs: fnsLocales.bs,
  el: fnsLocales.el,
  hu: fnsLocales.hu,
  mk: fnsLocales.mk,
  ro: fnsLocales.ro,
  ru: fnsLocales.ru,
  sr: fnsLocales.sr,
  uk: fnsLocales.uk,
}

interface DurationFormaOptions {
  format?: string[]
  zero?: boolean
  delimiter?: string
}

interface DurationFormat {
  years?: number
  months?: number
  weeks?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

type Duration = 'months' | 'hours' | 'minutes'

const trimCountryCode = (locale: Locale): Locale => locale.split('-')[0].toLowerCase() as Locale

const getLocale = (): any => {
  const locale = i18n.getLanguage()
  const trimmedLocale = trimCountryCode(locale)

  return localesMap[locale] ?? localesMap[trimmedLocale] ?? /* istanbul ignore next */ fnsLocales.enGB
}

const parse = (string: string, tz?: TimeZone): Date => {
  if (tz !== 'UTC') return fns.parseISO(string)

  // The rAPI has started passing dates with the time zone, but only for newly created fields,
  // so we need to remove the time zone to ensure the similar format with the older ones.
  const parsedDate = fns.parseISO(`${string.split('+')[0]}+00:00`)

  return fnsTz.utcToZonedTime(parsedDate, tz)
}

const today = (): Date => new Date()

const formatInLocal = (date: Date, template: string): string => fns.format(date, template, { locale: getLocale() })
const formatInUtc = (date: Date, template: string): string =>
  fnsTz.formatInTimeZone(date, 'UTC', template, {
    locale: getLocale(),
  })

const format = (date: Date, template: string, displayInUtc = false): string =>
  displayInUtc ? formatInUtc(date, template) : formatInLocal(date, template)

const formatDate = (date: Date): string => format(date, 'yyyy-MM-dd')

const formatTime = (date: Date, displayInUtc = false): string => format(date, 'HH:mm', displayInUtc)

const formatDateISO = (date: Date): string => format(date, "yyyy-MM-dd'T'HH:mm")

const parseUTCDateTime = <T extends string | null>(
  date: T,
  time: string | null,
  tz?: TimeZone,
): T extends null ? Date | null : Date => (date == null ? (null as any) : parse(`${date}T${time ?? ''}+00:00`, tz))

const formatYearMonth = (date: Date): string => format(date, 'MMMM yyyy')

const formatDateTimeText = (date: Date): string => format(date, 'MMM dd, yyyy, HH:mm')

const formatDateText = (date: Date): string => format(date, 'MMM dd, yyyy')

const durationToObject = (start: Date | number, end: Date | number, template: Duration[]): Record<string, number> => {
  const formatted: globalThis.Duration = fns.intervalToDuration({ start, end })
  const format = {
    months: (formatted.years as number) * 12 + (formatted.months as number),
    hours: (formatted.days as number) * 24 + (formatted.hours as number),
    minutes: formatted.minutes,
  }

  return Object.fromEntries(
    Object.entries(format).filter(([key, value]) => template?.includes(key as Duration) && !!(value as number)),
  ) as Record<string, number>
}

const durationToString = (start: Date | number, end: Date | number, template: Duration[]): string =>
  Object.entries(durationToObject(start, end, template))
    .map(([key, value]) => {
      const replaced = key.replace('hours', 'h').replace('minutes', 'm')

      return `${value}${replaced}`
    })
    .join(' ')

const ceilHour = (date: Date): string => {
  const updatedDate = fns.setMinutes(fns.addHours(date, 1), 0)

  return format(updatedDate, 'HH:mm')
}

const startOfDay = (): string => '00:00'

const endOfDay = (): string => '23:59'

const formatDuration = (duration: DurationFormat, options?: DurationFormaOptions): string => {
  return fns.formatDuration(duration, { ...options, locale: getLocale() })
}

const isAfterOrEqual = (a: Date, b: Date): boolean => fns.isAfter(a, b) || fns.isEqual(a, b)

export default {
  today,
  parse,
  format,
  formatYearMonth,
  durationToObject,
  formatDate,
  formatTime,
  formatDateISO,
  durationToString,
  ceilHour,
  startOfDay,
  endOfDay,
  parseUTCDateTime,
  formatDuration,
  formatDateTimeText,
  formatDateText,
  isAfterOrEqual,
}
