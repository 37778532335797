import { BookingPassenger, PassengerSeat } from '@api/booking'
import { ConnectionPassengers } from '@api/connections'
import { SeatsList } from '@hooks/useSeatSelection'
import ancillaryUtils from '@lib/ancillary'
import passengerUtils from '@lib/passengers'
import url from '@lib/url'
import utils from '@lib/utils'
import { DiscountCardsState } from '@pages/Checkout/Discounts'
import { CheckoutFormData, Seats } from '@pages/Checkout/hooks/useInitialFormValues'
import { PassengerData } from '@stores/checkout'
import { ParamsStore } from '@stores/params'

const SUPPORTED_FEES = ['MRKP', 'SURCHARGE']

export interface PassengerSeatInfo extends PassengerSeat {
  price: number
}

const getSeatsInfo = (seats: SeatsList, passengerIndex: number, initialInboundIndex: number = 0): PassengerSeatInfo[] =>
  Object.entries(seats ?? {}).map(([segment, list]) => {
    const [seat] = list.slice(passengerIndex, passengerIndex + 1)

    return {
      segmentIndex: Number(segment) + initialInboundIndex,
      seatCode: seat?.code ?? '',
      price: Number(seat?.price),
    }
  })

const getSeatsParam = (seats: PassengerSeatInfo[]): PassengerSeat[] =>
  seats.map(({ seatCode, segmentIndex }) => ({ seatCode, segmentIndex }))

const getPassengerSeats = (index: number, seats: Seats): PassengerSeatInfo[] => {
  const { outbound, inbound } = seats
  const lastIndex = Object.keys(outbound).length

  const outboundSeat = getSeatsInfo(outbound, index)
  const inboundSeat = getSeatsInfo(inbound, index, lastIndex)

  return [...outboundSeat, ...inboundSeat].filter(({ seatCode }) => seatCode)
}

interface SeatsAssignmentProps {
  passengers: PassengerData[]
  data?: CheckoutFormData
  seatAncillaries?: boolean
}

const assignSeatsToPassengers = ({ passengers, data, seatAncillaries }: SeatsAssignmentProps): BookingPassenger[] =>
  passengers.map<BookingPassenger>((passenger, index) =>
    utils.object.compact({
      ...passenger,
      seats: data?.seats && getSeatsParam(getPassengerSeats(index, data.seats)),
      ancillaries: data && ancillaryUtils.getPaxAncillariesParams(data, index, seatAncillaries),
    }),
  )

const passengersSeatsAndTypes = ({
  data,
  seatAncillaries,
}: Omit<Required<SeatsAssignmentProps>, 'passengers'>): BookingPassenger[] =>
  data.passengers.map<BookingPassenger>((passenger, index) =>
    utils.object.compact({
      type: passenger.type,
      seats: data.seats && getSeatsParam(getPassengerSeats(index, data.seats)),
      ancillaries: ancillaryUtils.getPaxAncillariesParams(data, index, seatAncillaries),
    }),
  )

const buildConfirmationPageUrl = (params: ParamsStore, redirectPath: string): string => {
  const path = url.build([redirectPath.split('?')[0]], params)

  return path.startsWith('http') ? /* istanbul ignore next */ path : `/${path}`
}

const filterFees = (fees: Fee[]): Fee[] => fees.filter(({ code }) => SUPPORTED_FEES.includes(code))

const calculateTotalFees = (fees: Fee[] = []): number =>
  filterFees(fees).reduce((acc, fee) => acc + fee.amount.fractional, 0)

const totalPriceWithFees = (price: Money, fees: Fee[]): Money => {
  return {
    fractional: price.fractional + calculateTotalFees(fees),
    currency: price.currency,
  }
}

const getConnectionPassengers = (list: Passenger.Param[]): ConnectionPassengers[] | null =>
  list.map(p => ({
    maxAge: p.maxAge ?? passengerUtils.getAgeRange([], p.type, p.pax).maxAge,
    pax: p.pax,
    cards: p.cards?.map(({ name, code }) => ({ name, code })),
  }))

const getCardsParams = (codes: DiscountCardsState | null): DiscountCode.Card[] =>
  Object.entries(codes ?? /* istanbul ignore next */ [])
    .map(([name, code]) => ({ name, code }))
    .filter(({ code }) => !!code) as DiscountCode.Card[]

export default {
  passengersSeatsAndTypes,
  assignSeatsToPassengers,
  buildConfirmationPageUrl,
  filterFees,
  calculateTotalFees,
  totalPriceWithFees,
  getConnectionPassengers,
  getCardsParams,
  getPassengerSeats,
}
