import makeApi from '@lib/api'

export interface PriceCalendarRequest {
  departureStation?: string | null
  departureCity?: string | null
  departureArea?: string | null
  arrivalStation?: string | null
  arrivalCity?: string | null
  arrivalArea?: string | null
  currency: Currency
  departureStartTime?: string | null
  departureDateStart: string
  departureDateEnd: string
  retailerPartnerNumber: number
}

export type PriceCalendarResponse = PriceCalendar

export default {
  load: makeApi.get<PriceCalendarRequest, PriceCalendarResponse>('/connections/price_calendar'),
}
