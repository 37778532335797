import { PassengersTypeParams } from '@loaders/connections'
import { PassengerData } from '@stores/checkout'

const MIN_PASSENGER_COUNT: Record<string, number> = {
  PNOS: 1,
  passengers: 1,
  default: 0,
}

const PASSENGER_PARAM: Record<string, Passenger.Param> = {
  PNOS: { maxAge: 65, pax: 1, type: 'PNOS' },
}

export const PASSENGERS_CODE = {
  infant: 'PINT',
  adult: 'PNOS',
  child: 'PCIL',
  youth: 'PYPO',
  senior: 'PSOE',
}

const buildTypesMap = (types: Passenger.Type[]): Record<string, Passenger.Type> => {
  return types.reduce<Record<string, Passenger.Type>>(
    (mem, item) => ({
      ...mem,
      [item.code]: item,
    }),
    {},
  )
}

const getMinCount = (type: string): number => MIN_PASSENGER_COUNT[type] ?? MIN_PASSENGER_COUNT.default

const typesToParams = (types: Passenger.Type[]): Passenger.Param[] =>
  types.map(p => ({ type: p.code, pax: getMinCount(p.code) }))

const getInitialPassengers = (supported: Passenger.Type[], passengers: Passenger.Param[] | null): Passenger.Param[] => {
  const initialPassenger = { type: 'PNOS', pax: 1, cards: [] }
  const list = supported.map(p => ({
    type: p.code,
    pax: passengers?.find(item => item.type === p.code)?.pax ?? 0,
    cards: passengers?.find(item => item.type === p.code)?.cards ?? [],
  }))

  return list.every(({ pax }) => Number(pax) === 0)
    ? list.map(passenger => (passenger.type === 'PNOS' ? initialPassenger : passenger))
    : list
}

const isInsideAge = ({ minAge, maxAge }: Passenger.Type, age: number): boolean => age >= minAge && age <= maxAge
const getTypeByAge = (types: Passenger.Type[], age: number): Passenger.Type | undefined =>
  types.find(type => isInsideAge(type, age))

const getCheckoutPassengers = (passengers: Passenger.Param[]): Passenger.Param[] =>
  passengers.flatMap(({ pax, cards, type, maxAge }) =>
    [...Array(Number(pax))].map((_, index) => ({
      type,
      maxAge,
      pax: 1,
      cards: cards?.filter(card => card.index === index),
    })),
  )

const getPaxCount = (passengers: Passenger.Param[] | null): number =>
  passengers?.reduce((acc, { pax }) => acc + Number(pax), 0) ?? 1

const getAgeRange = (supported: Passenger.Type[], type: string, pax: number): PassengersTypeParams => {
  /* istanbul ignore next */
  const { maxAge = PASSENGER_PARAM.PNOS.maxAge as number } =
    supported.find(p => p.code === type) ?? /* istanbul ignore next */ {}

  return { maxAge, pax }
}

const getPassengerTypeParams = (passengers: Passenger.Param[], supported: Passenger.Type[]): PassengersTypeParams[] =>
  passengers.flatMap(({ type, pax, cards }) => {
    const ageRange = getAgeRange(supported, type, pax)

    if (cards && cards.length > 0) {
      const uniqueIndexes = [...new Set(cards.map(({ index }) => index))]

      return new Array(uniqueIndexes.length + 1)
        .fill({ maxAge: ageRange.maxAge, pax, cards, type })
        .map((item, index) => {
          const paxIndex = uniqueIndexes[index]

          if (paxIndex == undefined) return { maxAge: item.maxAge, pax: item.pax - uniqueIndexes.length }

          return {
            maxAge: item.maxAge,
            pax: 1,
            cards: item.cards.filter((card: DiscountCode.Card) => card.index === paxIndex),
          }
        })
        .filter(p => p.pax > 0)
    }

    return ageRange
  })

const buildPassengerTypes = (passengers: Passenger.Param[] | null, supported: Passenger.Type[]): Passenger.Param[] => {
  if (!passengers) return [PASSENGER_PARAM.PNOS]

  return getPassengerTypeParams(passengers, supported).map(item => ({
    type: getTypeByAge(supported, item.maxAge)?.code as string,
    pax: item.pax,
    cards: item.cards,
    maxAge: item.maxAge,
  }))
}

const reducePassengers = (passengers: Passenger.Param[]): Passenger.Param[] =>
  passengers.reduce<PassengerData[] | Passenger.Param[]>((acc, curr) => {
    const pax = acc.find(item => item.type === curr.type)
    if (!pax) return [...acc, { ...curr, type: curr.type, pax: curr.pax, cards: curr.cards }]

    pax.pax += curr.pax
    pax.cards = [...(pax.cards ?? /* istanbul ignore next */ []), ...(curr.cards ?? [])]
      .flat()
      .filter(item => item.name)

    return acc
  }, [])

const getPassengerCode = (type: (keyof typeof PASSENGERS_CODE)[]): string[] => type.map(item => PASSENGERS_CODE[item])

const filterPassengers = (passengers: PassengerData[]): PassengerData[] =>
  passengers
    .filter(({ type, pax }) => type && pax)
    .map(item => ({
      ...item,
      cards: item.cards?.map(({ name, code }) => ({ name, code })),
    }))

const getPassengerList = (passengers: PassengerData[]): Passenger.Param[] =>
  passengers.reduce<Passenger.Param[]>((acc, { type, pax, cards }) => {
    const obj = acc.find(p => p.type === type)

    if (!obj) return [...acc, { type, pax, cards }]

    obj.pax += 1

    return acc
  }, [])

const sortPassengers = (passengers: Passenger.Type[]): Passenger.Type[] =>
  passengers.sort((a, b) => {
    if (b.code === PASSENGERS_CODE['adult']) return 1

    return b.maxAge - a.maxAge
  })

export default {
  buildTypesMap,
  getMinCount,
  typesToParams,
  getInitialPassengers,
  getPaxCount,
  getCheckoutPassengers,
  getPassengerTypeParams,
  getAgeRange,
  getPassengerCode,
  filterPassengers,
  getPassengerList,
  sortPassengers,
  getTypeByAge,
  buildPassengerTypes,
  isInsideAge,
  reducePassengers,
}
