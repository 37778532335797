import cn from 'classnames'
import React, { ReactElement, useState } from 'react'

import IsReturnTrip from '@components/IsReturnTrip'
import Loader from '@components/Loader'
import Money from '@components/Money'
import PassengersList from '@components/PassengersList'
import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import date from '@lib/date'
import { useTranslation } from '@lib/i18n'
import passengersUtils from '@lib/passengers'
import sdk from '@lib/sdk'
import tripTypeUtils from '@lib/tripType'
import url from '@lib/url'
import { useVacancyLoader } from '@loaders/vacancy'
import { useSettings } from '@queries/settings'
import { Button } from '@ui'

import '@sdk/Route/Widget/index.scss'

interface WidgetProps {
  retailerPartnerNumber: RetailerNumber
  marketingCarrierCode: CarrierCode
  departureStation: StationCode
  arrivalStation: StationCode
  locale: Locale
  currency: Currency
  target: SDK.Target
  carrier: {
    tradeName: string
    passengerTypes: Passenger.Type[]
  }
}

const Widget = ({ carrier, ...props }: WidgetProps): ReactElement => {
  const { marketingCarrierCode, retailerPartnerNumber, departureStation, arrivalStation, currency, locale, target } =
    props
  const { passengerTypes } = carrier
  const [isReturn, setIsReturn] = useState(false)
  const [passengers, setPassengers] = useState(() => passengersUtils.typesToParams(passengerTypes))
  const [{ checkout }] = useSettings()

  const filteredPassengers = passengers.filter(p => p.pax > 0)

  const { isLoading, data: vacancy } = useVacancyLoader({
    departureStationCode: departureStation,
    arrivalStationCode: arrivalStation,
    passengers: filteredPassengers,
    fareClass: tripTypeUtils.getStandardFareClass(isReturn),
    marketingCarrierCode,
    retailerPartnerNumber,
    currency,
    locale,
  })

  const { t } = useTranslation()

  const handleSubmit = (): void => {
    const buildCheckoutURL = checkout.type === 'new' ? url.checkout.express : url.checkout.old.express
    const checkoutUrl = buildCheckoutURL({
      arrivalStationCode: arrivalStation,
      departureStationCode: departureStation,
      fareClass: tripTypeUtils.getStandardFareClass(isReturn),
      departureTime: date.parse(vacancy?.departureTime as string, 'UTC'),
      passengers: filteredPassengers,
      retailerPartnerNumber,
      marketingCarrierCode,
      currency,
      locale,
      deviceId: amplitude.getDeviceId(),
    })

    sdk.redirect(checkoutUrl, target)
  }

  return (
    <div className="p-6 sdk-route column">
      <h4 className={cn('cell', 'mb-4', bem('sdk-route', 'title'))}>{carrier.tradeName}</h4>
      <div className={cn('cell', 'mb-4', bem('sdk-route', 'type'))}>
        <IsReturnTrip layout="row" value={isReturn} onChange={setIsReturn} />
      </div>
      <div className={cn('cell', 'mb-4', bem('sdk-route', 'passengers'))}>
        <PassengersList onChange={setPassengers} availableTypes={passengerTypes} value={passengers} />
      </div>
      <div className="cell mb-4">
        <div className={cn('row', bem('sdk-route', 'price'))}>
          <div className={cn({ 'cell-2': isLoading, 'cell-5': !isLoading })}>
            <Loader loading={isLoading}>{vacancy?.vacant && <Money {...vacancy.price} />}</Loader>
          </div>
        </div>
      </div>
      <div className={cn('cell', bem('sdk-route', 'submit'))}>
        <Button onClick={handleSubmit}>{t('sdk.route.submit')}</Button>
      </div>
    </div>
  )
}

export default Widget
