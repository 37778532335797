import React, {
  FocusEvent,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  KeyboardEvent,
} from 'react'

import useArrowControls from '@hooks/useArrowControls'
import useClickOutside from '@hooks/useClickOutside'
import bem from '@lib/bem'
import utils from '@lib/utils'
import { Input, Option } from '@ui'

import '@ui/Autocomplete/index.scss'

interface AutocompleteProps<T> {
  value: T | null
  onSelect: (value: T, position: number) => void
  inputValue: string
  onInputChange: (value: string) => void
  inputIcon?: ReactNode
  options: T[]
  renderOption: (option: T) => ReactNode
  getOptionChildren: (option: T) => T[]
  onInputFocus?: (e: FocusEvent) => void
  onInputBlur?: (e: FocusEvent) => void
  placeholder?: string | null
  inputErrorMessage?: string | null
  inputDisabled?: boolean
  emptyOptionMessage?: string | null
  loading: boolean
}

const Autocomplete = <Item,>({
  value,
  inputValue,
  inputIcon,
  options,
  renderOption,
  getOptionChildren,
  onInputChange,
  onSelect,
  onInputFocus,
  onInputBlur,
  placeholder,
  inputErrorMessage,
  emptyOptionMessage,
  inputDisabled,
}: AutocompleteProps<Item>): ReactElement => {
  const [opened, setOpened] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const id = useId()

  useClickOutside(ref, () => {
    setOpened(false)
  })

  const allOptions = useMemo(() => utils.array.flatten(options, getOptionChildren), [getOptionChildren, options])

  const handleSelect = (option: Item, event?: MouseEvent): void => {
    event?.stopPropagation()
    const position = allOptions.indexOf(option) + 1
    onSelect(option, position)
    setOpened(false)
  }

  const { onKeyDown, activeItem, resetActiveItem } = useArrowControls(allOptions, handleSelect, value)

  const handleKeyDown = (e: KeyboardEvent): void => {
    /* istanbul ignore next: cypress doesn't support .type({tab}) command */
    if (e.key === 'Tab') setOpened(false)
    onKeyDown(e)
  }

  const renderOptions = (list: Item[], level: number = 0): ReactNode => {
    return list.map((option, index) => (
      <Fragment key={String(id) + String(index)}>
        <Option
          value={option}
          onSelect={handleSelect}
          selected={option === value}
          active={option === activeItem}
          offset={level ? level * 45 : 16}
          data-tag="autocomplete-option"
        >
          {renderOption(option)}
        </Option>
        {renderOptions(getOptionChildren(option), level + 1)}
      </Fragment>
    ))
  }

  const listClassNames = bem('ui-autocomplete', 'list', { opened })

  useEffect(() => {
    if (!opened) {
      resetActiveItem()
    }
  }, [opened, resetActiveItem])

  return (
    <div
      className="ui-autocomplete"
      ref={ref}
      onFocus={() => {
        setOpened(true)
      }}
      onClick={() => {
        setOpened(true)
      }}
    >
      <Input
        value={inputValue}
        onChange={onInputChange}
        errorMessage={inputErrorMessage}
        label={placeholder}
        icon={inputIcon}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        disabled={inputDisabled}
        onKeyDown={handleKeyDown}
        trim={false}
      />
      {opened && (
        <div className={listClassNames} data-tag="autocomplete-options">
          {renderOptions(options)}
          {!options.length && <div className="ui-autocomplete__error-message">{emptyOptionMessage}</div>}
        </div>
      )}
    </div>
  )
}

export default Autocomplete
