import makeApi, { ResponsePostprocessing } from '@lib/api'
import { transformResponse } from '@lib/api/transformers/vacancy'

export interface VacancyRequest {
  marketingCarrierCode: CarrierCode
  retailerPartnerNumber: RetailerNumber
  departureStationCode: StationCode | null
  arrivalStationCode: StationCode | null
  currency: Currency
  locale: Locale
  passengers: Passenger.Param[]
  fareClass?: string | null
  departureDate?: string | null
  departureTime?: string | null
  arrivalDate?: string | null
  arrivalTime?: string | null
  returnDepartureDate?: string | null
  returnDepartureTime?: string | null
  returnArrivalDate?: string | null
  returnArrivalTime?: string | null
  include?: string | null
  cards?: DiscountCode.Card[] | null
  bookingId?: string | null
  returnFareClass?: string | null
}

export interface VacancyData {
  fees: Fee[]
  originalPrice: number
  departureTime: string
  arrivalTime: string
  returnDepartureTime: string | null
  returnArrivalTime: string | null
  reason?: NonVacancyReason
  termsAndConditions?: TermsConditions
  ancillaries: Ancillary.Item[]
  appliedCards: AppliedCard[]
}

export interface VacancyAvailableResponse extends VacancyData {
  vacant: true
  price: Money
}

interface VacancyUnavailableResponse extends VacancyData {
  vacant: false
  price: Record<string, never>
}

export type VacancyResponse = VacancyAvailableResponse | VacancyUnavailableResponse

const config: ResponsePostprocessing = {
  convertValues: {
    totalPrice: 'Price',
    price: 'Price',
    amount: 'Price',
  },
}

export default {
  load: makeApi.get<VacancyRequest, VacancyResponse>(
    { type: 'connections/vacancy', old: '/vacancy', new: '/connections/vacancy' },
    {
      responsePostprocessing: config,
      transformResponse: <T, U, P>(response: T, rawResponse: U, requestParams: P) =>
        transformResponse(response as VacancyRequest, rawResponse, requestParams as VacancyRequest) as T,
    },
  ),
}
