import React, { FocusEvent, ReactElement, ReactNode, useEffect, useState } from 'react'

import Option from '@components/LocationAutocomplete/Option'
import amplitude from '@lib/analytics/amplitude'
import { useTranslation } from '@lib/i18n'
import locationUtils from '@lib/location'
import { useSuggestionsLoader } from '@loaders/suggestions'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Autocomplete } from '@ui'

export interface LocationAutocompleteProps {
  value: Location.NamedItem | null
  onChange: (value: Location.NamedItem) => void
  searchValue: string
  setSearchValue: (value: string) => void
  fieldType: Location.Direction
  onInputFocus?: () => void
  onInputBlur?: (e: FocusEvent) => void
  placeholder?: string
  inputIcon?: ReactNode
  departureLocation?: Location.NamedItem | null
  arrivalLocation?: Location.NamedItem | null
  errorMessage?: string | null
  filterCodes?: string[]
  filterSuggestion: (data: Location.Suggestion[] | null) => Location.Suggestion[]
  reset?: () => void
  disabled?: boolean
}

const adjustLocale = (locale: Locale): Locale => {
  const [lang] = locale.split('-')

  return lang.toLowerCase() as Locale
}

const LocationAutocomplete = ({
  value,
  onChange,
  searchValue,
  setSearchValue,
  onInputFocus,
  onInputBlur,
  placeholder,
  inputIcon,
  departureLocation,
  fieldType,
  errorMessage: inputErrorMessage,
  filterCodes,
  filterSuggestion,
  reset,
  disabled,
}: LocationAutocompleteProps): ReactElement => {
  const [{ locale, retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const [{ suggestions }] = useSettings()
  const { t } = useTranslation()
  const [initialSuggestionsLoaded, setInitialSuggestionsLoaded] = useState<boolean>(false)
  const [shouldLoadSuggestions, setShouldLoadSuggestions] = useState<boolean>(false)
  const [notifyAmplitude, setNotifyAmplitude] = useState<boolean>(false)

  const [searchText] = searchValue.split(',', 1)
  const {
    isLoading,
    errorCode: requestError,
    data,
  } = useSuggestionsLoader({
    q: searchText,
    retailerPartnerNumber,
    fieldType,
    carriers: marketingCarrierCode ? [marketingCarrierCode] : undefined,
    locale: adjustLocale(locale),
    departureLocation,
    locationType: suggestions.display,
    enabled: shouldLoadSuggestions,
    notifyAmplitude,
    filterCodes,
    count: suggestions.count,
  })

  useEffect(() => {
    if (!value) return

    const text = locationUtils.makeDisplayName(value)
    setSearchValue(text)
  }, [value, setSearchValue])

  useEffect(() => {
    if (!searchValue) setInitialSuggestionsLoaded(false)
  }, [searchValue])
  useEffect(() => {
    !initialSuggestionsLoaded && data && searchValue && setInitialSuggestionsLoaded(true)
  }, [data, initialSuggestionsLoaded, searchValue])

  const loading = isLoading
  const emptyErrorCode = !loading && data?.length === 0 ? 'errors.autocomplete.emptyResult' : null
  const errorCode = requestError ? 'errors.internalError' : emptyErrorCode

  const onBlur = (e: FocusEvent): void => {
    onInputBlur?.(e)
    setShouldLoadSuggestions(false)
  }

  const onFocus = (): void => {
    onInputFocus?.()
    setShouldLoadSuggestions(!initialSuggestionsLoaded)
    setNotifyAmplitude(false)
  }

  const onInputChange = (value: string): void => {
    if (!value) reset?.()

    setSearchValue(value)
    setShouldLoadSuggestions(true)
    setNotifyAmplitude(true)
  }

  const onSelect = (suggestion: Location.NamedItem, position: number): void => {
    const station = { type: suggestion.type, code: suggestion.code }
    amplitude.suggestions.select({ position, station, fieldType, stringSize: suggestion.name.length })
    onChange(suggestion)
  }

  return (
    <Autocomplete
      value={value as Location.Suggestion}
      inputValue={searchValue}
      options={filterSuggestion(data)}
      renderOption={(item: Location.Suggestion): ReactNode => {
        return <Option item={item} highlight={searchValue} active={value?.code === item.code} />
      }}
      getOptionChildren={({ children }) => children}
      onInputChange={onInputChange}
      onSelect={onSelect}
      onInputFocus={onFocus}
      emptyOptionMessage={errorCode && t(errorCode)}
      loading={loading}
      placeholder={placeholder}
      inputIcon={inputIcon}
      inputErrorMessage={inputErrorMessage}
      onInputBlur={onBlur}
      inputDisabled={disabled}
    />
  )
}

export default LocationAutocomplete
