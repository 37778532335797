import { CarrierConfigResponse } from '@api/carrier'
import { SettingsResponse } from '@api/settings'
import { Settings } from '@queries/settings/types'

const getLogo = (settingsLogo?: string, carrierLogo?: string): string => {
  if (settingsLogo != null && !settingsLogo.endsWith('demo.png')) return settingsLogo

  return carrierLogo ?? settingsLogo ?? ''
}

export const merge = (settingsData: SettingsResponse, carrierData: CarrierConfigResponse): Settings => {
  return {
    ...settingsData,
    ...carrierData,
    governmentId: { ...carrierData.governmentId, ...settingsData.governmentId },
    logo: {
      ...carrierData.logo,
      ...settingsData.logo,
      url: getLogo(settingsData.logo.url, carrierData.logo?.url),
    },
  }
}
