import cn from 'classnames'
import React, { MouseEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'

import useArrowControls from '@hooks/useArrowControls'
import useClickOutside from '@hooks/useClickOutside'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { Icon } from '@ui'
import DropdownMenu from '@ui/Dropdown/Menu'

import '@ui/Dropdown/index.scss'

export interface DropdownItem<T> {
  label?: ReactNode
  value: T
  selectedLabel?: ReactNode
  getClass?: ({ selected }: { selected?: boolean }) => string
  searchValue?: string
}

export interface DropdownProps<T> {
  items: DropdownItem<T>[]
  value: T | null
  onChange: (item: T, event?: MouseEvent) => void
  onReset?: (event?: MouseEvent) => void
  resettable?: boolean
  pinnedValues?: T[]
  searchable?: boolean
  label?: string | null
  disabled?: boolean
  checkedMark?: boolean
  className?: string
  errorMessage?: string | null
  required?: boolean
  tag?: string
  focus?: boolean
}

const Dropdown = <TValue extends string>({
  disabled,
  className,
  errorMessage,
  required,
  ...restProps
}: DropdownProps<TValue>): ReactElement => {
  const { value, items, label, tag } = restProps
  const [opened, setOpened] = useState(false)
  const close = (): void => {
    setOpened(false)
  }
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()

  useClickOutside(ref, () => {
    if (!isMobile) setOpened(false)
  })

  const selectItem = (item: DropdownItem<TValue>): void => {
    restProps.onChange(item.value)
    setOpened(false)
  }
  const selectedItem = items.find(({ value: itemValue }) => value === itemValue)
  const { activeItem, onKeyDown, resetActiveItem } = useArrowControls<DropdownItem<TValue>>(
    restProps.items,
    selectItem,
    selectedItem,
  )

  const toggleOpened = (): void => {
    setOpened(!opened)
  }

  const currentItem = items.find(item => item.value === value)

  const dropdownBem = bem('ui-dropdown', { disabled })
  const dropdownClassNames = cn(dropdownBem, className)

  const dropdownLabelClassNames = bem('ui-dropdown', 'label', { outlined: opened, error: errorMessage })
  const placeholderTextClassNames = bem('placeholder', 'text', {
    empty: !value,
    closed: !opened,
    opened,
  })

  const focusOnDropdown = (): void => {
    ref.current?.focus()
  }

  useEffect(() => {
    if (!opened) resetActiveItem()
  }, [opened, resetActiveItem])

  return (
    <div
      tabIndex={0}
      data-tag={tag}
      className={dropdownClassNames}
      ref={ref}
      onClick={focusOnDropdown}
      onKeyDown={onKeyDown}
    >
      <div className={dropdownLabelClassNames} onClick={toggleOpened}>
        <div className="label__placeholder">
          {label && <div className={placeholderTextClassNames}>{`${label}${required ? '*' : ''}`}</div>}
          <div className="placeholder__value">{currentItem?.selectedLabel ?? currentItem?.label ?? '\u00A0'}</div>
        </div>
        <Icon name="chevron-down" size="small" />
      </div>
      {errorMessage && (
        <div className="row start ui-dropdown__error-message">
          <Icon name="alert" size="small" />
          <span>{errorMessage}</span>
        </div>
      )}
      <DropdownMenu {...restProps} placeholder={label} activeItem={activeItem?.value} onClose={close} opened={opened} />
    </div>
  )
}

export default Dropdown
