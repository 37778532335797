import config from '@config'
import linkerParam from '@lib/analytics/ga/linkerParam'
import date from '@lib/date'
import url from '@lib/url'
import { ConnectionParams, ContextParams } from '@lib/url/checkout'
import utils from '@lib/utils'

export interface ExpressParams extends Omit<ConnectionParams, 'arrivalTime'>, Omit<ContextParams, 'ga'> {}

export interface FullParams extends ConnectionParams, ContextParams {
  arrivalTime: Date
  price?: number
  seats?: Seat.TripParams
  cards?: DiscountCode.Card[] | null
}

const express = (params: ExpressParams): string => {
  const parameters = utils.object.compact({
    retailer_booking_number: params.retailerBookingNumber,
    departure_station_code: params.departureStationCode,
    departure_time: date.formatDateISO(params.departureTime),
    arrival_station_code: params.arrivalStationCode,
    fare_class: params.fareClass,
    marketing_carrier_code: params.marketingCarrierCode,
    passengers: params.passengers?.filter(p => p.pax > 0),
    retailer_partner_number: params.retailerPartnerNumber,
    express: true,
    currency: params.currency,
    locale: params.locale,
    deviceId: params.deviceId,
  })

  return url.build([config.checkoutUrl.old], parameters)
}

const full = (params: FullParams): string => {
  const parameters = utils.object.compact({
    ...params.ga,
    _gl: linkerParam.get(),
    departure_time: date.formatDateISO(params.departureTime),
    departure_station_code: params.departureStationCode,
    arrival_time: date.formatDateISO(params.arrivalTime),
    arrival_station_code: params.arrivalStationCode,
    fare_class: params.fareClass,
    marketing_carrier_code: params.marketingCarrierCode,
    retailer_booking_number: params.retailerBookingNumber,
    passengers: params.passengers?.filter(p => p.pax > 0),
    pax: params.pax,
    currency: params.currency,
    locale: params.locale,
    retailer_partner_number: params.retailerPartnerNumber,
    return_departure_time: params.returnDepartureTime ? date.formatDateISO(params.returnDepartureTime) : null,
    return_arrival_time: params.returnArrivalTime ? date.formatDateISO(params.returnArrivalTime) : null,
    seats: params.seats && utils.object.compact(params.seats),
    deviceId: params.deviceId,
    cards: params.cards,
  })

  return url.build([config.checkoutUrl.old], parameters, 'brackets')
}

export default {
  express,
  full,
}
