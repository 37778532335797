const DEFAULT_ICON = 'percent'

const ICONS: Record<string, string> = {
  PROMOCODE: 'gift',
  CORPORATECODE: 'percent',
  default: DEFAULT_ICON,
}

const getIcon = (code: string): string => ICONS[code] ?? ICONS.default

export default {
  getIcon,
}
