import cn from 'classnames'
import React, { MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@ui/Option/index.scss'

interface OptionProps<T> {
  value: T
  onSelect: (value: T, event?: MouseEvent) => void
  children: ReactNode
  separated?: boolean
  selected?: boolean
  offset?: number
  active?: boolean
  checkedMark?: boolean
  className?: string
  scroll?: boolean
  focus?: boolean
}

const Option = <T,>({
  value,
  onSelect,
  checkedMark,
  selected,
  offset,
  active,
  children,
  className,
  separated,
  scroll = true,
  focus = true,
  ...rest
}: OptionProps<T>): ReactElement => {
  const optionRef = useRef<HTMLDivElement>(null)
  const optionClasses = useMemo(() => bem('ui-option', { selected, active, separated }), [active, selected, separated])
  const onOptionClick = (event: MouseEvent): void => {
    onSelect(value, event)
  }

  useEffect(() => {
    if (selected && scroll && focus) {
      optionRef.current?.scrollIntoView({ block: 'center' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll])

  useEffect(() => {
    if (active && scroll && focus) {
      optionRef.current?.scrollIntoView({ block: 'nearest' })
    }
  }, [active, scroll, focus])

  return (
    <div
      style={{ paddingLeft: offset }}
      ref={optionRef}
      className={cn(className, optionClasses, 'row', 'space-between')}
      onClick={onOptionClick}
      {...rest}
    >
      <div className={bem('ui-option', 'label', { shorten: checkedMark })}>{children}</div>
      {checkedMark && selected && (
        <div className={bem('ui-option', 'checkmark')}>
          <Icon name="checkout" size="medium" />
        </div>
      )}
    </div>
  )
}

export default Option
