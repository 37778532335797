import { addMonths, endOfMonth, isAfter, isBefore, startOfDay } from 'date-fns'
import { Field, useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import { SearchFormState } from '@components/SearchForm'
import config from '@config'
import useValidators from '@hooks/useValidators'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import DatePickerField from '@ui/DatePicker/Field'

interface DatesFieldSetProps {
  prices: PriceCalendar | null
  isPriceLoading: boolean
  isDepartureDateDisabled?: (date: Date) => boolean
  onToggle?: (opened: boolean) => void
}

const DatesFieldSet = (props: DatesFieldSetProps): ReactElement => {
  const { t } = useTranslation()
  const { required } = useValidators()
  const {
    values: { departureDate, returnDate, isReturnTrip },
    setFieldValue,
  } = useFormikContext<SearchFormState>()
  const minDate = new Date()
  const maxDate = addMonths(minDate, config.searchBarDateRange)
  const isAllowedDepartureDate = (date: Date): boolean =>
    isBefore(date, startOfDay(new Date())) || isAfter(date, endOfMonth(maxDate))
  const isAllowedReturnDate = (date: Date): boolean =>
    isBefore(date, startOfDay(departureDate)) || isAfter(date, endOfMonth(maxDate))

  const { isDepartureDateDisabled = isAllowedDepartureDate, prices, isPriceLoading, onToggle } = props

  const resetReturnDate = (departureDate: Date): void => {
    if (returnDate && isBefore(returnDate, startOfDay(departureDate))) {
      setFieldValue('returnDate', departureDate)
    }
  }

  return (
    <div className={bem('search-form', 'dates')}>
      {!isReturnTrip ? (
        <Field
          component={DatePickerField}
          name="departureDate"
          isTileDisabled={isDepartureDateDisabled}
          label={t('searchBar.departureDateLabel')}
          minDate={minDate}
          maxDate={maxDate}
          prices={prices}
          isPriceLoading={isPriceLoading}
          onToggle={onToggle}
        />
      ) : (
        <div className="search-form__compound-date-select">
          <Field
            component={DatePickerField}
            name="departureDate"
            isTileDisabled={isDepartureDateDisabled}
            label={t('searchBar.departureDateLabel')}
            minDate={minDate}
            maxDate={maxDate}
            onChange={resetReturnDate}
            prices={prices}
            isPriceLoading={isPriceLoading}
          />
          <Field
            component={DatePickerField}
            validate={required}
            name="returnDate"
            withIcon={false}
            initialValue={departureDate}
            isTileDisabled={isAllowedReturnDate}
            label={t('searchBar.returnDateLabel')}
            minDate={minDate}
            maxDate={maxDate}
            prices={prices}
            isPriceLoading={isPriceLoading}
          />
        </div>
      )}
    </div>
  )
}

export default DatesFieldSet
