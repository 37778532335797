interface Config {
  version: string
  release: string
  env: 'test' | 'development' | 'production'
  sentry: {
    enabled: boolean
    dsn: string
  }
  api: {
    distribusion: ApiUrl
    railAgent: ApiUrl
    portal: ApiUrl
    suggestionUrl: string
    translationsUrl: string
    realtimeUrl: string
  }
  searchResultUrl: string
  portalPageUrl: string
  publicPath: string
  searchBarDateRange: number
  trackTripMaxDaysOffset: number
  destinationsPageSize: number
  defaultDepartureTime: string
  maxPassengers: number
  amtrakMaxPassengers: number
  expressMaxPassengers: number
  googleMapsPublicKey: string
  googleMapsRealtimeVisualizerMapId: string
  bookingStatus: {
    timeout: number
    maxAttempts: number
  }
  checkoutUrl: {
    old: string
    new: string
  }
  reservationStatus: {
    timeout: number
    maxAttempts: number
  }
  amplitudeApiKey: string
  adyen: {
    env: string
    publicKey: string
    description: string
  }
  vgs: {
    vaultId: string
    routeId: string
    env: 'sandbox' | 'live'
  }
  fallback: {
    currency: {
      supported: Currency[]
      default: Currency
    }
    locale: {
      supported: Locale[]
      default: Locale
    }
    pax: number
  }
  resolve: {
    retailerPartnerNumber: Record<string, number>
    searchResultUrl: Record<string, string>
    checkoutPageUrl: Record<string, string>
  }
  scripts: {
    enabled: boolean
  }
  settings: {
    topCurrencies: Currency[]
    topCountries: string[]
  }
  suggestions: Record<string, { filter: { byCountries: string[]; byCities: string[] } }>
  fareSplitViewLength: number
  validityBlacklistedTypes: string[]
  retailerDiscounts: Record<number, { supportedPassengers?: string[]; discounts?: string[] }>
  passengersValidity: {
    byRpn: Record<string, { required: string[]; check: string[]; relation: string } | undefined>
  }
  expressCheckout: {
    carriersWithAnyDirectionTrips: string[]
  }
  cardsLimit: number
  forcedFareClass: Record<string, Record<string, ForcedFareClass> & ForcedFareClass>
  forcedExtras: Record<string, { seatSelection?: { enabled: boolean } }>
  priceCalendarDateRange: number
  accommodation: Record<Partners, Record<Locale | 'default', string>>
  distribusionDomain: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __CONFIG__: Config

const config = { ...__CONFIG__ }

export default config
