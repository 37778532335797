/* istanbul ignore file */
import config from '@config'
import abtasty from '@lib/analytics/abtasty'
import amplitude from '@lib/analytics/amplitude'
import ga from '@lib/analytics/ga'
import gtm from '@lib/analytics/gtm'
import hotjar from '@lib/analytics/hotjar'
import maxymiser from '@lib/analytics/maxymiser'

type ServiceKey = keyof Omit<Analytics.Settings, 'defaults' | 'webloyalty'> | keyof Analytics.Settings['defaults']
interface ServiceInstance {
  init: (data: any) => void
}

const services: Record<ServiceKey, ServiceInstance | undefined> = {
  amplitude,
  hotjar,
  gtm,
  ga,
  maxymiser,
  abtasty,
}

type InitializeCallback = (key: ServiceKey, data: any) => void

const initializeDefaultAnalytics = (settings: Analytics.Settings, callback: InitializeCallback): void => {
  Object.entries(settings.defaults).forEach(([key, value]) => {
    const isAvailable = (settings[key as keyof Analytics.Settings] as Analytics.Override)?.include_default ?? true

    if (isAvailable) {
      const data: Analytics.IdConfig = { container_id: value }
      callback(key as ServiceKey, data)
    }
  })
}

const initializeCustomAnalytics = (
  { defaults: _, ...settings }: Analytics.Settings,
  callback: InitializeCallback,
): void => {
  Object.entries(settings).forEach(([key, value]) => callback(key as ServiceKey, value))
}

interface InitParams {
  only?: ServiceKey[]
}

const init = (settings: Analytics.Settings, params: InitParams = {}): void => {
  const { enabled } = config.scripts
  if (!enabled) return
  const initCallback: InitializeCallback = (key, value) => {
    if (params.only != null && !params.only.includes(key)) return
    services[key]?.init(value)
  }

  initializeDefaultAnalytics(settings, initCallback)
  initializeCustomAnalytics(settings, initCallback)
}

export default {
  init,
}
