import React, { MutableRefObject, RefObject } from 'react'
import { createRoot, Root } from 'react-dom/client'

import config from '@config'
import url from '@lib/url'
import utils from '@lib/utils'

type SourceExtension = 'js' | 'css'
type SourceType = 'sdk' | 'sdk-fonts'

const appendStyles = (container: Node, type: SourceType): void => {
  const link = document.createElement('link')
  link.href = getSourcePath('css', type)
  link.rel = 'stylesheet'

  container.appendChild(link)
}

const appendFonts = (container: Node): void => {
  const path = getSourcePath('css', 'sdk-fonts')
  const fontElement = document.querySelector(`link[href$="${path}"]`)

  /* istanbul ignore else */
  if (fontElement == null) appendStyles(container, 'sdk-fonts')
}

const mountInShadowDom = (root: HTMLElement): Root => {
  let { shadowRoot } = root

  if (!shadowRoot) {
    shadowRoot = root.attachShadow({ mode: 'open' })
    appendStyles(shadowRoot, 'sdk')
    appendFonts(document.head)
  }

  return createRoot(shadowRoot)
}

const mountPopupContainer = (): RefObject<HTMLElement> => {
  const popupContainer = document.createElement('div')
  popupContainer.style.position = 'absolute'
  popupContainer.style.top = '0'
  popupContainer.style.left = '0'
  document.body.appendChild(popupContainer)

  const root = mountInShadowDom(popupContainer)
  const ref: MutableRefObject<HTMLElement | null> = { current: null }

  root.render(
    <div
      ref={element => {
        ref.current = element
      }}
    />,
  )

  return ref
}

const getSourcePath = (extension: SourceExtension, type: SourceType = 'sdk'): string => {
  const prefix = config.publicPath.startsWith('http') ? /* istanbul ignore next */ '' : location.origin
  const parts = [prefix, config.publicPath, `${type}.${config.version}.${extension}`]

  return url.build(parts.filter(path => utils.string.trim(path, '/') !== ''))
}

const redirect = (url: string, target: SDK.Target): void => {
  if (target === 'new') {
    window.open(url)
  } else {
    window.location.assign(url)
  }
}

export default {
  mountInShadowDom,
  getSourcePath,
  redirect,
  mountPopupContainer,
}
