import priceCalendarAPI, { type PriceCalendarRequest, type PriceCalendarResponse } from '@api/priceCalendar'
import { makeLoader } from '@lib/loader'

interface UsePriceCalendarLoader {
  params: PriceCalendarRequest
}

export const usePriceCalendarLoader = makeLoader<UsePriceCalendarLoader, PriceCalendarResponse>({
  key: 'priceCalendar',
  loader: ({ params }) => priceCalendarAPI.load(params),
  enabled: ({ params }) =>
    [params.departureStation, params.departureArea, params.departureCity].some(i => i && i.length > 0) &&
    [params.arrivalStation, params.arrivalArea, params.arrivalCity].some(i => i && i.length > 0),
  staleTime: 1000 * 60 * 10,
})
